import { fetchItemShop } from "@/game-fortnite/fetches/item-shop.mjs";
import fetchReactions from "@/game-fortnite/fetches/reactions.mjs";
import { pathParamToOfferId } from "@/game-fortnite/utils/transform-offerid.mjs";

export async function fetchData([offerIdParam]) {
  const offerId = pathParamToOfferId(offerIdParam);
  const itemShop = await fetchItemShop();
  const offer = itemShop?.sections
    ?.map((section) => section.offers)
    .flat()
    .find((offer) => offer.offerId === offerId);
  const ids = offer?.items.map((item) => item.urlName);

  if (!ids || ids?.length === 0) return;

  return fetchReactions(ids);
}

export default fetchData;
